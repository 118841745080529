import axios from "axios";
import axiosRetry from "axios-retry";
import { ElNotification } from 'element-plus'

let baseApi;

if (process.env.NODE_ENV === 'development') {
  // baseApi = 'http://183.215.122.144:1553'
  baseApi = 'https://data.dawei.art'
}else{
  baseApi = 'https://data.dawei.art'
}

// axios.defaults.baseURL = ''

axios.defaults.timeout = 0;


const service = axios.create({
  baseURL: baseApi
})

service.interceptors.request.use((config) => {
  let reg = /^http/;
  if (!reg.test(config.url)) {
    config.headers = {
      ...config.headers,
      Authorization: localStorage.getItem('token')
    }
  }
  return config;
}, (error) => {

})

service.interceptors.response.use((response) => {
  let reg = /^http/;
  if (!reg.test(response.config.url)) {
    if (parseInt(response.data.code) === 405) {
      ElNotification.error({
        duration: 2,
        message: '登录失效，请重新登录',
        key: 'login',
      })
      //  重新登录
      setTimeout(()=>{
        window.location.replace('/#/login');
      }, 500)
      return false
    }
    if (response.data.message !== null && response.data.message.length > 0) {
      ElNotification.error({
        duration: 2,
        message: response.data.message,
        key: 'err msg'
      })
      return false
    }
    if (response.data.success){
      return response.data.data;
    }
  }
  return response;
}, error => {

})

axiosRetry(service, {
  retries: 3,
  retryCondition: (error) => {
    return error.code === 'ECONNABORTED' || !error.response;
  }
})


export default service;
